@import 'styles/colors';
@import 'styles/breakpoints';

.button {
  @include media('desktop') {
    padding: 24px 32px;
    font-size: 20px;
  }

  cursor: pointer;

  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 16px 24px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $text-10;
  text-transform: uppercase;
  text-wrap: nowrap;
  letter-spacing: 0.5px;

  border: none;
  border-radius: 8px;

  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  &.default {
    color: $text-10;
    background-color: $accent-second;

    &:hover {
      color: $accent-second;
      background-color: transparent;
      box-shadow: 0 0 0 1px $accent-second inset;
    }
  }

  &.circuit {
    color: $accent-second;
    background-color: transparent;
    box-shadow: 0 0 0 1px $accent-second inset;

    &:hover {
      color: $text-10;
      background-color: $accent-second;
    }
  }
}
