@import 'styles/colors';
@import 'styles/breakpoints';

.dropdownContainer {
  position: relative;

  button {
    text-wrap: nowrap;
  }

  .dropdownContent {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);

    display: flex;
    flex-direction: column;
    row-gap: 10px;

    padding: 10px 16px;

    text-wrap: nowrap;

    opacity: 0;
    background-color: $text_60;
    border-radius: 8px;

    transition: opacity 0.3s;

    &.opened {
      position: absolute;
      z-index: 10000;
      opacity: 1;
    }

    &.left {
      right: 0;
      left: unset;
      transform: translate(0, 100%);
    }
  }
}

.LangButton {
  @include media('laptop') {
    gap: 10px;
    padding: 16px 8px;
  }

  @include media('desktop') {
    gap: 12px;
  }

  display: flex;
  gap: 6px;
  align-items: center;

  padding: 0;

  color: $text-60;

  background-color: transparent;
  border: none;

  svg {
    @include media('laptop') {
      display: block;
    }

    display: none;
  }

  &:hover {
    cursor: pointer;
    color: $accent-second;
  }
}

.flagImageWrapper {
  @include media('laptop') {
    width: 28px;
    height: 20px;
  }

  @include media('desktop') {
    width: 32px;
    height: 24px;
  }

  position: relative;

  overflow: hidden;

  width: 24px;
  height: 18px;

  border-radius: 4px;

  img {
    object-fit: cover;
  }
}

.picker {
  display: grid;
  row-gap: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  .langItem {
    @include media('desktop') {
      font-size: 20px;
      line-height: 26px;
    }

    cursor: pointer;

    display: flex;
    gap: 8px;
    column-gap: 8px;
    align-items: center;

    &:hover {
      color: $accent-second;
    }

    img {
      object-fit: cover;
      border-radius: 4px;
    }

    &.active {
      pointer-events: none;
      color: $accent-second;
    }
  }
}
