@import 'styles/breakpoints';
@import 'styles/colors';

.footer {
  .footerSection {
    @include media('big_mobile') {
      padding: 64px 32px;
    }

    @include media('laptop') {
      padding: 52px 32px;
    }

    padding: 64px 16px;
    background-color: $background-100;
  }

  &Top {
    @include media('laptop') {
      margin-bottom: 56px;
    }

    margin-bottom: 48px;
  }
}

.grid {
  display: grid;
}

.navlinks {
  @include media('big_mobile') {
    flex-flow: row wrap;
    gap: 48px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include media('laptop') {
    flex-flow: row nowrap;
  }

  @include media('desktop') {
    column-gap: 128px;
  }

  display: flex;
  flex-direction: column;
  row-gap: 36px;
  align-items: center;
  justify-content: space-between;
}

.navlink {
  @include media('laptop') {
    text-align: unset;
  }

  @include media('desktop') {
    &:nth-of-type(3) {
      margin-right: auto;
    }
  }

  display: grid;
  grid-template-rows: min-content 1fr;
  row-gap: 8px;
  text-align: center;

  &.disclaimer {
    @include media('big_mobile') {
      margin-top: unset;
    }

    max-width: 680px;
    margin-top: 12px;
    color: $text-30;
  }

  .navlinkTitle {
    text-wrap: nowrap;
  }

  .sublinks {
    @include media('big_mobile') {
      text-align: start;
    }

    display: grid;
    grid-auto-rows: min-content;
    row-gap: 8px;

    a {
      color: $text-30;

      &:hover {
        color: $accent-second;
      }
    }
  }
}

.copyright {
  @include media('desktop') {
    font-size: 20px;
  }

  text-align: center;

  a {
    color: $link-10;
  }
}

.logoAndSocials {
  @include media('big_mobile') {
    flex-direction: row;
  }

  @include media('desktop') {
    margin-bottom: 48px;
  }

  display: flex;
  flex-direction: column;
  row-gap: 36px;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 32px;

  text-align: center;

  .logo {
    @include media('laptop') {
      width: 256px;
      height: 71px;
    }

    position: relative;
    width: 176px;
    height: 50px;
  }

  .social {
    @include media('big_mobile') {
      gap: 16px;
    }

    @include media('laptop') {
      gap: 36px;
    }
  }
}
