// Primary colors
$black: #000;
$midnight-express: #051733;
$tangaroa: #0b2040;
$sapphire: #132c54;
$catalina-blue: #233d69;
$resolution-blue: #2a4574;
$fun-blue: #335287;

// Secondary colors
$white: #fff;
$solitude: #eff0f3;
$solitude-10: #e0e3e9;
$link-water: #c2cedc;
$echo-blue: #a7b5ca;
$rock-blue: #899bb4;
$kashmir-blue: #546a8b;
$chambray: #405678;

// Accents colors
$mountain-meadow: #23b580;
$wild-watermelon: #fb5262;
$light-sea-green: #23b59a;
$lightning-yellow: #ffa127;
$safety-orange: #f6650a;
$goldenrod: #daa520;
$dodger-blue: #0288ff;
$dark-cerulean: #17448a;
$dark-cerulean-10: #0f3c88;
$dark-pastel-red: #bf3b22;