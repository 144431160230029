// Mobile
$mobile-width: 360px;

// Big mobile
$big-mobile-width: 480px;

// Laptop
$laptop-width: 1200px;

// Desktop
$desktop-width: 1920px;

// Breakpoints
$breakpoints: (
  mobile-width: $mobile-width,
  big-mobile-width: $big-mobile-width,
  laptop-width: $laptop-width,
  desktop-width: $desktop-width,
);

@mixin mobile {
  @media (min-width: $mobile-width) {
    @content;
  }
}

@mixin big_mobile {
  @media (min-width: $big-mobile-width) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin media($breakpoints...) {
  @each $key in $breakpoints {
    @if $key == 'mobile' {
      @include mobile {
        @content;
      }
    } @else if $key == 'big_mobile' {
      @include big_mobile {
        @content;
      }
    } @else if $key == 'laptop' {
      @include laptop {
        @content;
      }
    } @else if $key == 'desktop' {
      @include desktop {
        @content;
      }
    }
  }
}

//To remove
$desktop: 1920px;
$laptop: 1200px;
$tablet: 768px;
$big-mobile: 480px;
