@import 'styles/breakpoints';
@import 'styles/colors';

.social {
  @include media('big_mobile') {
    gap: 16px;
  }

  @include media('laptop') {
    gap: 36px;
  }

  display: flex;
  gap: 36px;

  &_icon {
    @include media('laptop') {
      width: 36px;
      height: 36px;
    }

    cursor: pointer;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    transition: transform 0.25s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}
