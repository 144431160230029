@import 'styles/breakpoints';
@import 'styles/colors';

.MenuWrapper {
  @include media('big_mobile') {
    height: 64px;
    padding: 12px 24px;
  }

  @include media('laptop') {
    height: 84px;
    padding: 16px 48px;
  }

  @include media('desktop') {
    height: 144px;
    padding: 36px;
  }

  position: sticky;
  z-index: 1000;
  top: 0;

  height: 60px;
  padding: 12px 18px;

  background-color: $background-90;

  &.withModal {
    .Modal {
      @include media('laptop', 'desktop') {
        display: none;
      }

      position: fixed;
      top: 56px;
      left: 0;

      overflow-x: hidden;
      overflow-y: auto;
      display: grid;

      width: 100%;
      height: calc(100% - 56px);
    }
  }
}

.Menu {
  @include media('laptop', 'desktop') {
    grid-template-areas: 'logo . links . actions';
    grid-template-columns: auto 0.5fr 1fr 0.5fr auto;

    div.Links {
      display: grid;
      align-self: center;
    }

    .actions {
      align-self: center;
    }

    .BurgerButton {
      display: none;
    }
  }

  display: grid;
  grid-template-areas: 'burger logo actions';
  grid-template-columns: auto 1fr auto;
  align-items: center;

  margin: 0 auto;

  .Logo {
    grid-area: logo;
  }

  .Links {
    display: none;
    grid-area: links;
  }

  .actions {
    @include media('laptop') {
      gap: 24px;
    }

    display: flex;
    grid-area: actions;
    gap: 8px;
    align-items: center;
  }

  .LangButton {
    &.langButtonDesktop {
      @include media('desktop') {
        display: flex;
        margin-right: 12px;
        font-size: 24px;
        font-weight: 500;
      }

      display: none;
    }

    &.langButtonMobile {
      @include media('desktop') {
        display: none;
      }
    }

    img {
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.BurgerButton {
  cursor: pointer;

  grid-area: burger;

  width: 24px;
  height: 24px;
  margin-right: 4px;
  padding: 0;

  background: none;
  border: none;

  &:hover {
    .Slice,
    .SliceShort {
      background-color: $text-10;
    }

    .close {
      color: $text-10;
    }
  }

  .burger {
    display: none;

    &.opened {
      display: grid;
    }
  }

  .close {
    display: none;
    color: $accent-second;

    &.opened {
      display: block;
    }
  }
}

.BurgerIcon {
  display: grid;
  row-gap: 4px;

  .Slice {
    width: 18px;
    height: 2px;
    background-color: $accent-second;
    border-radius: 2px;
  }

  .SliceShort {
    width: 9px;
    height: 2px;
    background-color: $accent-second;
    border-radius: 2px;
  }
}

.LogoLink {
  display: flex;
  align-items: center;
}

.Logo {
  @include media('laptop') {
    width: 128px;
    height: 36px;
  }

  @include media('desktop') {
    width: 256px;
    height: 72px;
  }

  cursor: pointer;

  display: flex;
  align-items: center;

  width: 80px;
  height: 22px;

  a {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.Links {
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
}

.Locale {
  @include media('desktop') {
    display: block;
  }

  display: none;
  text-transform: uppercase;
}

.menuButton {
  @include media('desktop') {
    font-size: 20px;
    line-height: 26px;
  }

  display: flex;
  gap: 8px;
  align-items: center;

  height: 56px;
  padding: 16px 24px;

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  color: $text-60;
  text-wrap: nowrap;

  background-color: transparent;
  border: unset;

  &:hover {
    cursor: pointer;
    color: $accent-second;
  }
}

.dropdownContainer {
  position: relative;

  button {
    text-wrap: nowrap;
  }

  .dropdownContent {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);

    display: flex;
    flex-direction: column;
    row-gap: 10px;

    padding: 10px 16px;

    text-wrap: nowrap;

    opacity: 0;
    background-color: $text_60;
    border-radius: 8px;

    transition: opacity 0.3s;

    &.opened {
      position: absolute;
      z-index: 10000;
      opacity: 1;
    }
  }

  .sublink {
    @include media('desktop') {
      font-size: 20px;
      line-height: 26px;
    }

    font-size: 16px;
    line-height: 20px;

    &:hover {
      color: $accent-second;
    }
  }
}
