@import 'styles/breakpoints';

$dot-color: #2b3360;

.not_found {
  overflow: hidden;
  display: grid;
  row-gap: 23px;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 58px);
  max-height: calc(100vh - 58px);

  text-align: center;

  background: radial-gradient($dot-color 3%, transparent 10%),
    radial-gradient($dot-color 3%, transparent 10%), #040b12;
  background-position: 0 0, 20px 15px, 0 0;
  background-size: 20px 15px, 20px 15px, auto;
}

.header {
  padding: 14px;

  &_title {
    font-size: 19px;
    font-weight: bold;
    color: #a7a9ac;
  }

  &_subtitle {
    font-size: 76px;
    font-weight: bold;
    color: #ffa800;
  }
}

.return {
  padding: 14px;
}

.return_button {
  max-width: 370px;
  margin: 0 auto;
}

.content {
  padding: 14px;
  font-size: 28px;
  font-weight: bold;
  color: white;
}

.cable {
  align-self: flex-end;
  padding-top: 14px;

  img {
    transform: translateX(-10%);
    width: 120%;
    object-fit: cover;
  }
}

@media (min-width: $tablet) {
  .cable {
    img {
      transform: translateX(-7%);
      width: 80%;
    }
  }
}
