@import './default-theme';

// Background
$background-50: $fun-blue;
$background-60: $resolution-blue;
$background-70: $catalina-blue;
$background-80: $sapphire;
$background-90: $tangaroa;
$background-100: $midnight-express;

// Transparent background
$bg-transparent-10: rgba($white, 0.1);

// Text
$text-10: $white;
$text-20: $solitude;
$text-30: $solitude-10;
$text-40: $link-water;
$text-50: $echo-blue;
$text-60: $rock-blue;
$text-70: $kashmir-blue;
$text-80: $chambray;

// Link
$link-10: $dodger-blue;

// Welcome promo background
$welcome-promo-steps-color: $dark-cerulean;
$welcome-promo-bg-color: $dark-cerulean-10;

// Brand
$brand-up: $mountain-meadow;
$brand-down: $wild-watermelon;
$accent-first: $light-sea-green;
$accent-second: $lightning-yellow;
$accent-third: $safety-orange;
$accent-fourth: $dodger-blue;
$increase-profit-color: $goldenrod;

// Info
$success-color: $mountain-meadow;
$error-color: $dark-pastel-red;
$warning-color: $lightning-yellow;

// Gradients
$brand-up-gradient: linear-gradient(270deg, $brand-up 0%, rgba($brand-up, 0) 100%);
$brand-down-gradient: linear-gradient(270deg, $brand-down 0%, rgba($brand-down, 0) 100%);
$fade-gradient: linear-gradient(180deg, rgb(2 24 58 / 0%) 0%, #02183a 50.49%);
$dashboard-graph-gradient: linear-gradient(
  180deg,
  rgba($dodger-blue, 0.1) 0%,
  rgba($dodger-blue, 0) 100%
);

// Utility
$white-utility: $white;
$black-utility: $black;

// Цвета для лоадера в полном экране
$fullscreen_loader_color: #9370db;
$fullscreen_loader_before_color: #ba55d3;
$fullscreen_loader_after_color: #f0f;

// OLD
$green: #23b580;
$lightGreen: #2aad7d;
$orange: #ffa127;
$yellow: #ffa800;
$white: #fff;
$darkblue: #4b5a71;
$blue: #30334e;
$lightgray: #f8f8f8;
$gray: #cdcdcd;
$grayBlue: #4b5a71;
$lightGrayBlue: #646e7c;
$brightOrange: #ffa127;
