@import 'styles/breakpoints';
@import 'styles/colors';

.Navigation {
  display: grid;
  width: 175px;
  color: white;
}

.MainLink {
  padding: 16px 14px;
  font-size: 12px;
}

.SubLink {
  display: block;
  padding: 16px 24px;
  font-size: 10px;
}

.SubLinks {
  overflow: hidden;
  max-height: 0;
  background-color: #232c3a;
  transition: max-height 0.25s ease;
}

.opened {
  .SubLinks {
    max-height: 300px;
  }
}

.LinkSection {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    top: 20px;
    right: 15px;
    transform-origin: center center;

    width: 7px;
    height: 5px;

    background-image: url('/images/util/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;

    transition: transform 0.2s ease-in-out;
  }

  &.opened {
    &::after {
      transform: rotate(180deg);
    }
  }
}

@media screen and (min-width: $laptop) {
  .Navigation {
    justify-content: center;
    width: 600px;
  }

  .LinkSection {
    display: none;
    width: 230px;

    &::after {
      content: none;
    }

    &.visible {
      display: block;
    }
  }

  .MainLink {
    padding: 20px 0;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid white;
  }

  .SubLinks {
    max-height: unset;
    padding-top: 10px;
    background-color: unset;
  }

  .SubLink {
    cursor: pointer;
    padding: 6px 0;
    font-size: 12px;

    &:hover {
      color: $orange;
    }
  }
}

.container {
  display: grid;
  grid-template-areas: '.' 'main' '.' 'socials';
  grid-template-rows: 1fr auto 1fr auto;
  height: 100%;
}

.navBlockItem {
  @include media('big_mobile') {
    row-gap: 16px;
  }

  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;

  text-align: center;
}

.navBlockItemTitle {
  font-size: 40px;
  font-weight: 500;
  color: $text-50;
}

.navBlockItemSublinks {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}

.navBlockWrapper {
  display: flex;
  grid-area: main;
  flex-direction: column;
  row-gap: 36px;
  align-items: center;

  padding: 0 16px;
}

.social {
  grid-area: socials;
  column-gap: 36px;
  justify-self: center;
}

// .navBlockItemSublink {
// }
