@import 'styles/colors';
@import 'styles/breakpoints';

.button {
  @include media('laptop') {
    font-size: 16px;
  }

  @include media('desktop') {
    font-size: 20px;
  }

  cursor: pointer;

  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: $text-10;
  text-wrap: nowrap;

  border-radius: 8px;

  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  &.default {
    @include media('big_mobile') {
      padding: 8px 16px;
    }

    @include media('laptop') {
      padding: 12px 20px;
    }

    @include media('desktop') {
      padding: 16px 24px;
    }

    padding: 6px 16px;
    background-color: $accent-second;

    &:hover {
      color: $accent-second;
      background-color: transparent;
      box-shadow: 0 0 0 1px $accent-second inset;
    }
  }

  &.circuit {
    @include media('big_mobile') {
      padding: 8px;
    }

    @include media('laptop') {
      padding: 12px 20px;
      color: $accent-second;
      background-color: transparent;
      box-shadow: 0 0 0 1px $accent-second inset;

      &:hover {
        color: $text-10;
        background-color: $accent-second;
      }

      img.icon {
        display: none;
      }

      span.text {
        display: block;
      }
    }

    @include media('desktop') {
      padding: 16px 24px;
    }

    padding: 6px;
    background-color: $text-70;

    &:hover {
      background-color: $accent-second;
    }

    .icon {
      display: block;
    }

    .text {
      display: none;
    }
  }
}
