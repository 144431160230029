@import 'styles/breakpoints';

@mixin text($fs, $lh) {
  font-size: $fs;
  line-height: $lh;
}

.typography {
  margin: 0;
}

.h1 {
  @include text(16px, normal);

  @include media('big_mobile') {
    @include text(20px, normal);
  }

  @include media('laptop', 'desktop') {
    @include text(24px, normal);
  }

  font-weight: 700;
  text-transform: uppercase;
}

.h2 {
  @include text(32px, 42px);

  @include media('big_mobile') {
    @include text(40px, 52px);
  }

  @include media('laptop') {
    @include text(64px, 72px);
  }

  @include media('desktop') {
    @include text(96px, 112px);
  }

  font-weight: 700;
}

.h3 {
  @include text(40px, 54px);

  @include media('big_mobile', 'laptop') {
    @include text(48px, 64px);
  }

  @include media('desktop') {
    @include text(64px, 84px);
  }

  font-weight: 700;
}

.h4 {
  @include text(24px, 32px);

  @include media('big_mobile') {
    @include text(24px, 32px);
  }

  @include media('laptop') {
    @include text(20px, 32px);
  }

  @include media('desktop') {
    @include text(32px, 42px);
  }

  font-weight: 700;
}

.h5 {
  @include text(20px, 28px);

  @include media('big_mobile') {
    @include text(24px, 28px);
  }

  @include media('laptop') {
    @include text(20px, 28px);
  }

  @include media('desktop') {
    @include text(32px, 42px);
  }

  font-weight: 700;
}

.h6 {
  @include text(16px, 22px);

  @include media('big_mobile') {
    @include text(20px, 28px);
  }

  @include media('laptop') {
    @include text(16px, 20px);
  }

  @include media('desktop') {
    @include text(24px, 32px);
  }

  font-weight: 700;
}

.pAccent {
  @include text(20px, 26px);

  @include media('big_mobile', 'laptop') {
    @include text(24px, 30px);
  }

  @include media('desktop') {
    @include text(36px, 46px);
  }
}

.pMain {
  @include text(14px, 24px);

  @include media('big_mobile', 'laptop') {
    @include text(14px, 24px);
  }

  @include media('desktop') {
    @include text(20px, 30px);
  }
}

.spanNumber {
  @include text(32px, 42px);

  @include media('big_mobile') {
    @include text(36px, 48px);
  }

  @include media('laptop') {
    @include text(24px, 32px);
  }

  @include media('desktop') {
    @include text(32px, 48px);
  }

  font-weight: 700;
}

.spanBtn {
  @include text(20px, 28px);

  @include media('big_mobile') {
    @include text(24px, 32px);
  }

  @include media('laptop') {
    @include text(14px, 18px);
  }

  @include media('desktop') {
    @include text(18px, 24px);
  }

  font-weight: 500;
  text-transform: uppercase;
}
