@import 'styles/breakpoints';
@import 'styles/colors';

.Sider {
  overflow-x: hidden;
  overflow-y: auto;
  display: block;

  width: 100%;
  height: 100%;

  .Content {
    overflow-y: auto;
    grid-area: content;

    width: 100%;
    height: 100%;

    animation: slide 0.25s ease forwards;
  }

  &.right {
    grid-template-areas: 'overlay content';
    grid-template-columns: 1fr auto;

    .Content {
      animation-name: slideleft;
    }
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideleft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.Content {
  padding-bottom: 40px;
  background-color: $background-90;
}

.Overlay {
  position: relative;
}

.CloseButton {
  cursor: pointer;
  position: absolute;
  width: 12px;
  height: 12px;
}

@media screen and (min-width: $laptop) {
  .CloseButton {
    width: 20px;
    height: 20px;
  }

  .Sider {
    &.left {
      .CloseButton {
        top: 45px;
        left: 35px;
      }
    }

    &.right {
      .CloseButton {
        top: 45px;
        right: 35px;
      }
    }
  }
}
